const config = {
  siteTitle: "Blancco X Bolttech", // Site title.
  siteTitleShort: "BB", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Blancco X Bolttech", // Alternative site title for SEO.
  siteLogo: "/assets/images/bolttech-logo.png", // Logo used for SEO and manifest.
  siteUrl: "https://buyback-uat.device.bolttech.asia", // Domain of your website without pathPrefix.
  pathPrefix: "/index", 
  fixedFooter: false, // Whether the footer component is fixed, i.e. always visible
  siteDescription: "Blancco X Bolttech BuyBack", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  siteRssTitle: "Blancco X Bolttech BuyBack", // Title of the RSS feed
  dateFromFormat: "YYYY-MM-DD", // Date format used in the frontmatter.
  dateFormat: "DD/MM/YYYY", // Date format for display.
  userEmail: "ashutosh.jha@puresoftware.com", // Email used for RSS feed's author segment
  copyright: "Copyright © 2022. Bolttech", // Copyright string for the footer of the website and RSS feed.
  favicon: "/assets/images/favicon.ico",
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
  config.siteRss = `/${config.siteRss}`;

module.exports = config;
