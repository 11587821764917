import React, { Component } from "react";
import Footer from "../Footer/footer";

import "./Main.scss";
import Header from "../Header/header";

class Main extends Component {
  render() {
    console.log("this.props",this.props);
    const { children } = this.props;
    return (
      <div className="main-content">
        <Header></Header>
        <div className="main-container">
          {children}
        </div>
        <Footer/>
      </div>
      
    );
  }
}

export default Main;
