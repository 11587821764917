import React from "react";
import { Helmet } from "react-helmet";
import "font-awesome/scss/font-awesome.scss";
import Main from "../components/Main/main";
import config from "../../data/SiteConfig";
import "./index.scss";
import "./global.scss";
import "./style.scss";

export default class MainLayout extends React.Component {
  
  render() {
    const { children } = this.props;
    return (
      <Main config={config} LocalTitle={this.props.title}>
        <div>
          <Helmet>
            <meta name="description" content={config.siteDescription} />
          </Helmet>
          {children}
        </div>
      </Main>
    );
  }
}
